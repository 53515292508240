const openNav = document.querySelector('.header__btn');
const toggleBtn = document.querySelector('.header__toggle');
const menu = document.querySelector('.header__menu');

toggleBtn.addEventListener('click', function () {
    if (toggleBtn.getAttribute('data-click') === '1') {
        toggleBtn.classList.add('active');
        menu.classList.add('header__menu--active');
        openNav.classList.remove('header__btn--fix');
        toggleBtn.setAttribute('data-click', 0);
    } else {
        toggleBtn.classList.remove('active');
        menu.classList.remove('header__menu--active');
        toggleBtn.setAttribute('data-click', 1);
    }
})